import React, { FC, memo, useCallback, useState, FocusEvent } from 'react';
import classNames from 'classnames';
import "./style.scss";

export interface ICheckboxProps {
  label?: string,
  name?: string,
  checked?: boolean,
  onChange?: (value: boolean, name?: string) => void,
}

const Checkbox: FC<ICheckboxProps> = ({ onChange, name, label, checked = false }) => {
  const [focus, setFocus] = useState(false);
  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    if (event.target.classList.contains('focus-visible')){
      setFocus(true);
    }
  }, [setFocus]);
  const handleBlur = useCallback(() => setFocus(false), [setFocus]);
  const handleChange = useCallback((event) => {
    const { checked } = event.target
    if (onChange) {
      onChange(checked, name)
    }
  }, [name, onChange])
  const checkboxClasses = classNames({
    'checkbox': true,
    'focus-visible': focus,
  });
  return (
    <label className={checkboxClasses}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {label}
    </label>
  );
};

export default memo(Checkbox);