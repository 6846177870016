import React, { FC, memo, useCallback, useState } from 'react';
import { useWatch } from 'services/api/models/watch';
import Layout from 'components/Layout';
import Main from 'components/Main';
import Form from './Form';
import Modal from 'components/Modal';
import FindYourRefContent from './modals/FindYourRefContent';
import ErrorContent from './modals/ErrorContent';
import "./style.scss";
import { useTranslation } from 'react-multi-lang';

const HomeView: FC = () => {
  const t = useTranslation();
  const [watch, setWatch] = useWatch();
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  
  const closeModal = useCallback(() => {
    setVisible(false);
    setError(false);
  }, [setVisible]);

  const openModal = useCallback(() => setVisible(true), [setVisible]);
  const contactConcierge = useCallback(() => setError((error) => {
    if (!error) {
      window.dataLayer.push({"event": "nologin"})
    }
    return true
  }), [setError]);

  const submit = useCallback((newWatch?: string) => {
    if (newWatch) {
      setWatch(newWatch).then((ok) => {
        if (!ok) {
          setError(true);
        } else {
          window.dataLayer.push({"event": "login"})
        }
      });
    } else {
      setError(true);
    }
  }, [setWatch, setError]);
  return (
    <div id="page-home">
      <Layout>
        <div className="side-image"></div>
        <Main>
          <h1>{t('APP_NAME')}</h1>
          <p>{t('APP_DESCRIPTION')}</p>
          <Form
            internalRef={watch?.internalRef}
            submit={submit}
            contactConcierge={contactConcierge}
            openModal={openModal}
          />
        </Main>
      </Layout>
      <Modal visible={visible} onClose={closeModal} content={FindYourRefContent} />
      <Modal visible={error} onClose={closeModal} content={ErrorContent} />
    </div>
  );
};

export default memo(HomeView);