import initRecost from 'recost';
import watchReducer, { IWatchState, watchInitialState } from './watch';
import observationReducer, { IObservationState, observationInitialState } from './observation';
import regionReducer, { IRegionState, regionInitialState } from './region';
import translationReduce, { ITranslationState, translationInitialState } from './translation';
import serviceReduce, { IServiceState, serviceInitialState } from './service';
import componentReducer, { IComponentState, componentInitialState } from './component'
import estimationReducer, { IEstimationState, estimationInitialState} from './estimation';

export interface IState extends IServiceState, ITranslationState, IWatchState, 
IObservationState, IRegionState, IComponentState, IEstimationState {};

const initialState: IState = {
  ...watchInitialState,
  ...observationInitialState,
  ...regionInitialState,
  ...translationInitialState,
  ...serviceInitialState,
  ...componentInitialState,
  ...estimationInitialState,
};

const reducers = [
  watchReducer,
  observationReducer,
  regionReducer,
  translationReduce,
  serviceReduce,
  componentReducer,
  estimationReducer,
];

export const {
  Provider,
  dispatch,
  useSelector,
  withState,
} = initRecost(initialState, reducers);