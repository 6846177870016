import { IBaseState, IAction } from "recost";
import { IEstimation } from "services/api/models/estimations";

export const FETCH_ESTIMATION_PENDING = 'FETCH_ESTIMATION_PENDING';
export const FETCH_ESTIMATION_FULFILLED = 'FETCH_ESTIMATION_FULFILLED';
export const FETCH_ESTIMATION_REJECTED = 'FETCH_ESTIMATION_REJECTED';

export interface IEstimationState extends IBaseState {
  estimation?: IEstimation,
  loadingEstimation: boolean,
  estimationError: boolean,
};

export const estimationInitialState: IEstimationState = {
  loadingEstimation: false,
  estimationError: false,
};

export default function estimationReducer(state: IEstimationState, action: IAction) {
  switch (action.type) {
    case FETCH_ESTIMATION_PENDING:
      return {
        ...state,
        loadingEstimation: true,
      };
    case FETCH_ESTIMATION_FULFILLED:
      return {
        ...state,
        estimation: action.payload,
        loadingEstimation: false,
        estimationError: false,
      };
    case FETCH_ESTIMATION_REJECTED:
      return {
        ...state,
        estimationError: true,
      };
    default:
      return state;
  };
};