import React, { FC } from 'react';
import './style.scss';
import ListItem from './ListItem';

export interface IListProps {
  title: string,
};

const List: FC<IListProps> = ({ children, title }) => {
  return (
    <fieldset className="list">
      <legend>{title}</legend>
      {children}
    </fieldset>
  )
}

export default List;
export { List, ListItem };