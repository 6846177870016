import React, { FC, memo, ReactText } from 'react';
import classNames from 'classnames';
import './style.scss';
import logo from 'assets/logo-header.svg';
import { vacWebsite } from 'constants/links';
import Input from 'components/Input';
import { useLanguages } from 'services/api/models/translations';
import { useTranslation } from 'react-multi-lang';
import { isInsideIframe } from 'utils';

const Header: FC = () => {
  const t = useTranslation();
  const { langs = [], lang, setLang } = useLanguages();
  const headerClasses = classNames({
    'header': true,
    'inside-iframe': isInsideIframe(),
  })
  return <>
    <header className={headerClasses}>
      <a rel="noopener noreferrer" target="_blank" href={vacWebsite}>
        <img alt={t('Vacheron Constantin')} src={logo} />
      </a>
      <Input small
        className="lang-select" 
        name="lang-select"
        type="select" 
        options={langs} 
        placeholder={t('HEADER_SELECT_LANGUAGE')}
        value={lang}
        onChange={setLang as (value: ReactText) => void}
      />
    </header>
  </>
}

export default memo(Header);