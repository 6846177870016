import React, { FC, memo, useCallback, useState, FocusEvent } from 'react';
import classNames from 'classnames';
import './style.scss';
import Input from 'components/Input';
import { useTranslation } from 'react-multi-lang';

const images = require.context('assets/components', true);

export interface IGridItem {
  label?: string,
  value?: string,
  active?: boolean,
  onSelect?: (value: string) => void,
  options?: { label: string, value: string, selected?: boolean }[],
  disabled?: boolean,
}

const GridItem: FC<IGridItem> = ({ disabled = false, onSelect, active = false, value, label, options = [] }) => {
  const [focus, setFocus] = useState(false);
  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    if (event.target.classList.contains('focus-visible')){
      setFocus(true);
    }
  }, [setFocus]);
  const handleBlur = useCallback(() => setFocus(false), [setFocus]);
  const handleSelect = useCallback((value) => {
    if (value) {
      onSelect?.(value);
    }
  }, [onSelect]);
  const handleChange = useCallback(() => {
    if (value) {
      onSelect?.(value)
    }
  },[onSelect, value]);
  const itemClasses = classNames({
    'grid-item': true,
    'active': active,
  });
  const t = useTranslation();
  return (
    <div className={itemClasses}>
      <label className={classNames({ 'focus-visible': focus })}>
        <img alt={label} src={images(`./${value}.svg`)} />
        <p>{label}</p>
        <input
          disabled={disabled}
          name={label}
          type="checkbox"
          checked={active}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </label>
      {active && options.length > 0 &&
        <div className="grid-item-options">
          <Input 
            name={label?.split(" ").join("_")}
            type="select"
            placeholder={`(${t('ADDSRV_CHOOSE_MATERIAL')})`}
            options={options}
            onChange={handleSelect}
            value={options.find(o => o.selected)?.value}
          />
        </div>
      }
    </div>
  );
};

export default memo(GridItem);