import { useHistory } from 'react-router-dom';
import { createBrowserHistory, History } from 'history'
import { scrollTop } from 'utils';
import { IEstimateParams } from './api/models/estimations';

export const history = createBrowserHistory();

export interface IQueryString extends Partial<IEstimateParams> {
  lang?: string
  print?: string
};

export function parse(hash: string) {
  const params: IQueryString = {};
  hash && hash.split('&').forEach((param) => {
    let [key, value] = param.split('=');
    if (value.startsWith('[')) {
      params[key as 'observations'] = value.substring(1, value.length-1).split(',');
    } else {
      params[key as 'id' | 'region'] = value;
    }
  });
  return params;
}

function stringify(params: IQueryString) {
  return (Object.keys(params) as (keyof IQueryString)[]).map((key) => {
    const value = params[key]
    if (Array.isArray(value)) {
      return `${key}=[${value.filter(v=>v).join(',')}]`;
    } else {
      return `${key}=${value}`
    }
  }).join('&');
}

export function routing(h: History = history) {
  const pathname = h.location.pathname;
  const search = h.location.search.startsWith('?')
    ? h.location.search.substring(1)
    : h.location.search;
  const params: IQueryString = parse(search);
  const setBasename = (basename: string) => {
    const currentPath = pathname.split('/').filter((name, i) => i > 1).join('/');
    h.push({
      pathname: `/${basename}/${currentPath}`,
      search: window.location.search.replace('?', ''),
    });
  }
  const navigateTo = (newPath: string, newParams: IQueryString = {}, clean = false) => {
    const basepath = pathname.split('/')[1];
    h.push({
      pathname: `/${basepath}${newPath}`,
      search: stringify(clean ? newParams : { ...params, ...newParams }),
    });
    scrollTop();
  };
  const setParams = (newParams: IQueryString, clean = false) => {
    h.push({
      pathname: window.location.pathname,
      search: stringify(clean ? newParams : { ...params, ...newParams }),
    });
  }
  return { 
    pathname: '/' + pathname.split('/').filter((name, i) => i > 1).join('/'),
    basename: pathname.split('/')[1],
    setBasename,
    navigateTo,
    params,
    setParams,
  }; 
}


export function useRouting() {
  const history = useHistory();
  return routing(history);
};