import './tagmanager'

export function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export function scrollTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function arrayEquals(array1: string[] = [], array2: string[] = []) {
  return !(
    array1.find((item) => !array2.includes(item)) || 
    array2.find((item) => !array1.includes(item))
  )
}

export function isInsideIframe() {
  return window.parent.frames.length > 0;
}

export function browserLanguages() {
  return window.navigator.language.toLowerCase().split('-');
}