import api from 'services/api';
import { useRouting } from 'services/routing';
import { useCallback, useEffect } from 'react';
import { dispatch, useSelector } from 'reducers';
import { FETCH_WATCH_PENDING, FETCH_WATCH_REJECTED, FETCH_WATCH_FULFILLED, DESELECT_WATCH } from 'reducers/watch';

export interface IWatchQuery {
  id?: string,
  cassRef?: string,
  serialNumber?: string,
};

export interface IWatch {
  id: string,
  cassRef: string,
  serialNumber?: string,
  internalRef: string,
  name: { [lang: string]: string },
  collection: { [lang: string]: string },
};

export function sanitizeInternalRef(value?: string) {
  return (value || '').toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
}

export function useWatch(): [IWatch|undefined, (value: string) => Promise<boolean>] {
  const { params, setParams, navigateTo } = useRouting();
  const { watch, loading } = useSelector((state) => ({
    watch: state.watch,
    loading: state.loadingWatch,
  }));

  const setWatch = useCallback((value: string) => {
    if (watch?.internalRef === value) {
      navigateTo('/diagnosis')
      return Promise.resolve(true)
    }
    const sanitized = sanitizeInternalRef(value);
    return getWatch(sanitized).then((watch?: IWatch) => {
      if (watch) {
        navigateTo('/diagnosis', { id: watch.id, services: [], observations: [], components: [] });
        return true
      }
      return false;
    }).catch(() => false);
  }, [navigateTo, watch]);

  useEffect(() => {
    if (params.id && params.id !== watch?.id && !loading) {
      getWatch(params.id);
    }
  }, [params.id, setParams, watch, loading]);

  useEffect(() => {
    if (!params.id && !loading) {
      dispatch({ type: DESELECT_WATCH });
    }
  }, [params.id, loading])

  return [ watch, setWatch ];
};

export function getWatch(value: string): Promise<IWatch | undefined> {
  if (value) {
    dispatch({ type: FETCH_WATCH_PENDING });
    return api.get(`/products/${value}`).then((watch) => {
      dispatch({ type: FETCH_WATCH_FULFILLED, payload: watch });
      return watch;
    }).catch(() => {
      dispatch({ type: FETCH_WATCH_REJECTED });
    });
  } else {
    dispatch({ type: DESELECT_WATCH });
    return Promise.resolve(undefined);
  }
};

export function searchWatch(search: string): Promise<IWatch[]> {
  return api.get(`/products?search=${search}`);
};