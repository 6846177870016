import { useCallback } from 'react';
import { setTranslations, useTranslation } from 'react-multi-lang';
import { useSelector } from 'reducers';
import api from './api';

api.get('/translations').then((translations) => {
  setTranslations(translations);
}).catch(() => {
  console.log('Cannot get translations');
});

export function useTranslations() {
  const t = useTranslation();
  const lang = useSelector((state) => state.lang) || 'en';
  const translate = useCallback((arg: {[lang: string]:string} | string = '', params?: any) => {
    if (typeof arg === 'string') {
      return t(arg, params);
    } else {
      return arg[lang] || arg['en'];
    }
  }, [lang, t]);
  return translate;
};