import { useEffect, useCallback } from 'react';
import { setLanguage } from 'react-multi-lang';
import { useSelector, dispatch } from 'reducers';
import { FETCH_LANGS_PENDING, FETCH_LANGS_REJECTED, FETCH_LANGS_FULFILLED, SET_LANG } from 'reducers/translation';
import api from 'services/api';
import { useRouting } from 'services/routing';
import { browserLanguages } from 'utils';

export interface ILang {
  label: string,
  value: string,
  current?: boolean,
};

export function useLanguages() {
  const { params, setBasename, basename } = useRouting();
  const { langs, loading, error, lang } = useSelector((state) => ({ 
    langs: state.langs, 
    error: state.langsError,
    loading: state.loadingLangs,
    lang: state.lang,
  }));

  const setLang = useCallback((lang: string) => {
    setBasename(lang);
    setLanguage(lang);
    dispatch({ type: SET_LANG, payload: lang });
    if (lang === 'ar') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  }, [setBasename]);

  useEffect(() => {
    if (langs.length === 0 && !loading && !error) {
      listLangs().then((langs = []) => {
        if (basename) {
          setLang(basename)
        } else {
          const browserLangs = browserLanguages();
          const lang = langs.find((lang) => {
            return browserLangs.includes(lang.value);
          });
          if (lang) {
            setLang(lang.value);
          } else {
            setLang('en')
          }
        }
      })
    }
  }, [langs, loading, error, setLang, params.lang, basename]);

  return { 
    langs, 
    setLang,
    lang: langs.find((l) => l.value === lang)?.value,
  };
}

export function listLangs(): Promise<ILang[]> {
  dispatch({ type: FETCH_LANGS_PENDING });
  return api.get('/translations/langs').then((langs) => {
    dispatch({ type: FETCH_LANGS_FULFILLED, payload: langs });
    return langs;
  }).catch(() => {
    dispatch({ type: FETCH_LANGS_REJECTED });
  });
}
