import React, { FC, useCallback, ReactNode, useRef, useState, KeyboardEvent, useEffect } from 'react';
import classNames from 'classnames';
import './style.scss';
import { KEY_ARROW_DOWN, KEY_ARROW_UP, KEY_ENTER } from 'constants/keyboard';

export interface IInputProps {
  autocomplete?: boolean,
  type?: string,
  label?: string | ReactNode,
  name?: string,
  value?: string | number,
  options?: Array<string | { label: string, value: string }>,
  onChange?: (value: string | number, name?: string,) => void,
  onSubmit?: () => void,
  error?: boolean,
  placeholder?: string,
  className?: string,
  small?: boolean,
}

const optionValue = (option: any): string => typeof option === 'string' ? option : option?.value;
const optionLabel = (option: any): string => typeof option === 'string' ? option : option?.label || option?.value;

const Input: FC<IInputProps> = ({ 
  autocomplete = false,
  label, 
  type = 'text',
  name, 
  value = '',
  onChange,
  onSubmit,
  options,
  error = false,
  placeholder,
  className = '',
  small = false,
}) => {

  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [selected, setSelected] = useState(-1);
  const handleFocus = useCallback(() => setOpen(true), [setOpen]);
  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (event.target && !ref.current?.contains(event.target as Node)) {
        setOpen(false);
      }
    }
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  }, [setOpen]);

  const handleChange = useCallback((event) => {
    setInvalid(false);
    setOpen(true);
    onChange?.(event.target.value, name);
  }, [name, onChange, setInvalid]);

  useEffect(() => setInvalid(error), [error, setInvalid]);

  const handleKey = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    switch(event.keyCode) {
      case KEY_ARROW_DOWN:
        setSelected(selected => selected < (options?.length || 0)-1 ? selected + 1 : selected);
        break;
      case KEY_ARROW_UP:
        setSelected(selected => selected > 0 ? selected - 1 : 0);
        break;
      case KEY_ENTER:
        event.preventDefault();
        const value = optionValue(options?.[selected])
        if (value) onChange?.(value, name)
        if (open) setOpen(false);
        else onSubmit?.()
        break;
    }
  }, [setSelected, selected, onChange, options, name, setOpen, open, onSubmit]);

  const handleSelect = useCallback((option) => () => {
    setOpen(false);
    onChange?.(optionValue(option), name);
  }, [onChange, name]);

  const inputClasses = classNames({ 
    'input': true,
    'small': small,
    [className]: className,
  });
  const ariaLabel = (typeof label === 'string' && label)  || placeholder || name || ''

  return (
    <div className={inputClasses} ref={ref}>
      {label && (
        <label
          id={`${name}-label`}
          htmlFor={`${name}-input`}>{label || placeholder || name}</label>
      )}
      {(type === 'select' && options)
        ? <select
            required
            data-value={value}
            aria-label={ariaLabel}
            id={`${name}-input`}
            onChange={handleChange}
            className={classNames({ invalid })}
            value={value}>
            {placeholder && (
              <option value="" disabled>{placeholder}</option>
            )}
            {options.map((option, index) =>
              <option key={index} value={optionValue(option)}>
                {typeof option === 'string' 
                  ? option 
                  : (option.label || option.value)
                }
              </option>
            )}
          </select>
        : <input 
            aria-label={ariaLabel}
            placeholder={placeholder}
            className={classNames({ invalid })}
            onFocus={handleFocus}
            onKeyDown={handleKey}
            autoCapitalize="none"
            autoComplete="off"
            id={`${name}-input`}
            type={type}
            name={name}
            value={value}
            onChange={handleChange} 
            role="combobox"
            aria-controls="expandedable"
            aria-expanded={open && (options?.length || 0) > 0}
            aria-autocomplete="list"
            aria-owns={`${name}-list`}
          />
      }
      {autocomplete && options && (options.length === 1 ? optionValue(options[0]) !== value : true) && (
        <ul
          role="listbox"
          id={`${name}-list`}
          className={classNames({ 'autocomplete': true, 'open': open })}
          >
          {options.map((option, index) => 
            <li
              id={`${name}-option-${index}`}
              role="option" 
              aria-selected={index === selected}
              key={index} 
              onClick={handleSelect(option)}>
                {optionLabel(option)}
            </li>
          )}
        </ul>
      )}
    </div>
  )
}

export default Input;