import React, { FC } from 'react';
import GridItem, { IGridItem } from './GridItem';
import classNames from 'classnames';
import './style.scss';

export interface IGridProps {
  disabled?: boolean,
}

const Grid: FC<IGridProps> = ({ children, disabled = false }) => {
  const gridClasses = classNames({
    'grid': true,
    'disabled': disabled,
  });
  return (
    <div role="group" className={gridClasses}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement<IGridItem>(child)) {
          return child;
        }
        return React.cloneElement<IGridItem>(child, {
          ...child.props,
          disabled,
        });
      })}
    </div>
  );
};

export default Grid;
export { Grid, GridItem };