import { useEffect, useCallback } from 'react';
import { useSelector, dispatch } from 'reducers';
import { FETCH_REGIONS_PENDING, FETCH_REGIONS_REJECTED, FETCH_REGIONS_FULFILLED } from 'reducers/region';
import api from 'services/api';
import { useRouting } from 'services/routing';
import { browserLanguages } from 'utils';

export interface IRegion {
  id: string,
  name: string,
  friendlyName: string,
  kitUrl?: string,
  currency: string,
  symbol?: string,
  current?: boolean,
  vat?: number,
};

export function useRegions() {
  const { params, setParams } = useRouting();
  const { regions, loading, error } = useSelector((state) => ({ 
    regions: state.regions, 
    error: state.regionsError,
    loading: state.loadingRegions, 
  }));

  const setRegion = useCallback((region: IRegion | string) => {
    setParams({ region: typeof region === 'string' ? region : region.id });
  }, [setParams]);

  useEffect(() => {
    if (regions.length === 0 && !loading && !error) {
      listRegions().then((regions = []) => {
        if (!params.region) {
          const browserLangs = browserLanguages()
          const region = regions.find((region) => {
            return browserLangs.some((lang) => {
              return region.id.toLowerCase().includes(lang);
            });
          });
          if (region) {
            setRegion(region);
          } else {
            setRegion('CHVA');
          }
        }
      })
    }
  }, [regions, loading, error, setRegion, params.region]);

  return { 
    regions, 
    setRegion,
    region: regions.find((r) => r.id === params.region),
  };
}

export function listRegions(): Promise<IRegion[]> {
  dispatch({ type: FETCH_REGIONS_PENDING });
  return api.get('/regions').then((regions) => {
    dispatch({ type: FETCH_REGIONS_FULFILLED, payload: regions });
    return regions;
  }).catch(() => {
    dispatch({ type: FETCH_REGIONS_REJECTED });
  });
}
