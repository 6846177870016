import React, { FC, memo } from 'react';
import './style.scss';

export interface ILayoutProps {};

const Layout: FC<ILayoutProps> = ({ children }) => {
  return (
    <main className="layout">
      {children}
    </main>
  );
};

export default memo(Layout);