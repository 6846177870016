import React from 'react';
import './style.scss';
import { useTranslation } from 'react-multi-lang';

export default () => {
  const t = useTranslation();
  return (
  <div id="find-watch-ref">
    <div className="watches">
      <div className="watch">
        <h3>{t('HOME_POPUP_SOLID_CASE')}</h3>
        <div className="legend">
          <div>{t('WORD_REF_NUMBER')}</div>
          <div>{t('WORD_TECH_NUMBER')}</div>
        </div>
      </div>
      <div className="watch">
        <h3>{t('HOME_POPUP_TRANSPARENT_CASE')}</h3>
        <div className="legend">
          <div>{t('WORD_REF_NUMBER')}</div>
          <div>{t('WORD_TECH_NUMBER')}</div>
        </div>
      </div>
    </div>
    <div className="text">
      <h2>{t('HOME_POPUP_TITLE')}</h2>
      <p>{t('HOME_POPUP_DESCRIPTION')}</p> 
      <section>
        <h3>{t('HOME_POPUP_SECTION1')}</h3>
        <p>{t('HOME_POPUP_COPY1')}</p>
      </section>
      <section>
        <h3>{t('HOME_POPUP_SECTION2')}</h3>
        <p>{t('HOME_POPUP_COPY2')}</p>
      </section>
    </div>
  </div>
  );
}