import { useEffect, useCallback } from 'react';
import api from 'services/api';
import { dispatch, useSelector } from 'reducers';
import { FETCH_COMPONENTS_PENDING, FETCH_COMPONENTS_FULFILLED, FETCH_COMPONENTS_REJECTED } from 'reducers/component';
import HttpError from '../HttpError';
import { useRouting } from 'services/routing';

export interface IMaterial {
  id: string,
  name: { [lang: string]: string },
  selected?: boolean,
};

export interface IComponent {
  id: string,
  name?: { [lang: string]: string },
  selected?: boolean,
  materials: IMaterial[],
};

export function mapComponents(components: IComponent[], selected: string[] = []): IComponent[] {
  return components.map((comp) => {
    const materials = comp.materials.map((material) => ({
      ...material,
      selected: selected.includes(material.id),
    }));
    return {
      ...comp,
      materials,
      selected: selected.includes(comp.id) || materials.some(m => m.selected),
    };
  });
}

export function selectedComponents(components: IComponent[], selected: string[] = []): IComponent[] {
  return mapComponents(components, selected).filter((comp) => comp.selected);
}

export function useComponents() {
  const { params, setParams } = useRouting();
  const components = useSelector((state) => state.components);
  const loading = useSelector((state) => state.loadingComponents);
  const error = useSelector((state) => state.componentsError);

  useEffect(() => {
    list();
  }, []);

  const toggleComponent = useCallback((id: string) => {
    const comp = components.find((comp) => comp.id === id || comp.materials.find(m => m.id === id));
    const compWithoutMaterial = components.find(c => c.id === id)
    if (comp && comp.id) {
      let selected = params.components || [];
      if (selected.find(s => s === id || compWithoutMaterial?.id === components.find(c => c.id === s || c.materials.find((m => m.id === s)))?.id)){
        selected = selected.filter(s => s !== id && compWithoutMaterial?.id !== components.find(c => c.id === s || c.materials.find((m => m.id === s)))?.id);
      }
      else if (selected.find(s => s === comp.id || comp.materials.find(m => m.id === s))) {
        selected = selected.filter((id) => id !== comp.id && !comp.materials.find(m => m.id === id));
        selected = [...selected, id]
      } 
      else {
        selected = [...selected, id];
      }
      setParams({ components: selected });
    }
  }, [components, setParams, params.components]);

  const clean = useCallback(() => {
    setParams({ components: [] });
  }, [setParams]);

  return { 
    components: mapComponents(components, params.components),
    selected: selectedComponents(components, params.components),
    loading, 
    error,
    toggleComponent,
    clean,
  };
}

export function list(productId?: string) {
  dispatch({ type : FETCH_COMPONENTS_PENDING });
  return api.get(`/components?productId=${productId}`).then((components: IComponent[]) => {
    if (components) {
      dispatch({ 
        type: FETCH_COMPONENTS_FULFILLED, 
        payload: components,
      });
      return components;
    } else {
      throw new HttpError({ status: 500 });
    }
  }).catch((error) => {
    dispatch({ type: FETCH_COMPONENTS_REJECTED });
    throw error;
  })
}
