import React, { memo, FC } from 'react'
import "./style.scss"

export interface IModalProps {
  content?: string
  children: any
}

const Mouseover: FC<IModalProps> = ({ children, content }) => {
  return (
    <div className={`mouseover ${content ? 'enabled' : ''}`}>
      {children}
      <div className="mouseover-popup">
        {content}
      </div>
    </div>
  );
};

export default memo(Mouseover);