import { IBaseState, IAction } from "recost";
import { IObservation } from "services/api/models/observations";

export const FETCH_OBSERVATIONS_PENDING = 'FETCH_OBSERVATIONS_PENDING';
export const FETCH_OBSERVATIONS_FULFILLED = 'FETCH_OBSERVATIONS_FULFILLED';
export const FETCH_OBSERVATIONS_REJECTED = 'FETCH_OBSERVATIONS_REJECTED';

export interface IObservationState extends IBaseState {
  observations: IObservation[],
  loadingObservations: boolean,
  observationsError: boolean,
};

export const observationInitialState: IObservationState = {
  observations: [],
  loadingObservations: false,
  observationsError: false,
};

export default function observationReducer(state: IObservationState, action: IAction) {
  switch (action.type) {
    case FETCH_OBSERVATIONS_PENDING:
      return {
        ...state,
        loadingObservations: true,
      };
    case FETCH_OBSERVATIONS_FULFILLED:
      return {
        ...state,
        observations: action.payload,
        loadingObservations: false,
      };
    case FETCH_OBSERVATIONS_REJECTED:
      return {
        ...state,
        observationsError: true,
      };
    default:
      return state;
  };
};