import React from 'react';
import { Provider } from 'reducers';
import RootView from './views/Root';

function App() {
  return (
    <Provider>
      <RootView />
    </Provider>
  )
}

export default App;
