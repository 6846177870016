import api from "services/api";
import { useEffect, useState } from "react";
import { dispatch, useSelector } from "reducers";
import { FETCH_ESTIMATION_FULFILLED, FETCH_ESTIMATION_REJECTED, FETCH_ESTIMATION_PENDING } from "reducers/estimation";
import { arrayEquals } from 'utils'

export interface IEstimateParams {
  id?: string,
  region?: string,
  observations?: string[],
  services?: string[],
  components?: string[],
  age?: number,
};

export interface IServiceEstimation {
  id: string,
  name: { [lang: string]: string },
  price: number | null,
  pricingGroup?: string,
  laborCost?: boolean,
};
export interface IEstimation {
  services: IServiceEstimation[],
  totalServices: number,
  components: IServiceEstimation[],
  totalComponents: number,
  total: number,
};

export function useEstimation(props: IEstimateParams) {
  const [params, setParams] = useState<IEstimateParams>({});
  useEffect(() => {
    if (
      props.id !== params.id || 
      props.region !== params.region || 
      !arrayEquals(props.observations, params.observations) ||
      !arrayEquals(props.services, params.services) ||
      !arrayEquals(props.components, params.components)
    ) {
      setParams(props);
    }
  }, [props, setParams, params]);

  const { estimation, error, loading, kitUrl } = useSelector((state) => ({
    estimation: state.estimation,
    error: state.estimationError,
    loading: state.loadingEstimation,
    kitUrl: state.regions.find(r => r.id === props.region)?.kitUrl
  }));

  useEffect(() => {
    if (!error && params.id) {
      estimate(params);
    }
  }, [error, params]);

  return {
    estimation, 
    error,
    loading,
    kitUrl,
  };
}

export function estimate(params: IEstimateParams): Promise<IEstimation> {
  dispatch({ type: FETCH_ESTIMATION_PENDING });
  return api.post('/estimations', params).then((estimation) => {
    dispatch({ type: FETCH_ESTIMATION_FULFILLED, payload: estimation });
    return estimation;
  }).catch((error) => {
    dispatch({ type: FETCH_ESTIMATION_REJECTED, payload: error })
    throw error;
  });
}