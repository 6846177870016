import { IBaseState, IAction } from "recost";
import { IService } from "services/api/models/services";

export const FETCH_SERVICES_PENDING = 'FETCH_SERVICES_PENDING';
export const FETCH_SERVICES_FULFILLED = 'FETCH_SERVICES_FULFILLED';
export const FETCH_SERVICES_REJECTED = 'FETCH_SERVICES_REJECTED';

export interface IServiceState extends IBaseState {
  services: IService[],
  loadingServices: boolean,
  servicesError: boolean,
};

export const serviceInitialState: IServiceState = {
  services: [],
  loadingServices: false,
  servicesError: false,
};

export default function serviceReducer(state: IServiceState, action: IAction) {
  switch (action.type) {
    case FETCH_SERVICES_PENDING:
      return {
        ...state,
        loadingServices: true,
      };
    case FETCH_SERVICES_FULFILLED:
      return {
        ...state,
        services: action.payload,
        loadingServices: false,
      };
    case FETCH_SERVICES_REJECTED:
      return {
        ...state,
        servicesError: true,
      };
    default:
      return state;
  };
};