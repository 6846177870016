import React, { FC, memo, useCallback, useState, FormEvent } from 'react';
import { useRouting } from 'services/routing';
import Navbar from 'components/Navbar';
import Layout from 'components/Layout';
import Sidebar from 'components/Sidebar';
import Main from 'components/Main';
import Button from 'components/Button';
import Footer from 'components/Footer';
import Grid, { GridItem } from 'components/Grid';
import Checkbox from 'components/Checkbox';
import { useComponents } from 'services/api/models/components';
import { useTranslations } from 'services/multilang';
import classNames from 'classnames';
import './style.scss';

const Spareparts: FC = () => {
  const routing = useRouting();
  const { components, selected, toggleComponent, clean } = useComponents();
  const t = useTranslations();
  const [checked, setChecked] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [error, setError] = useState(false);
  const onSelect = useCallback((id: string) => {
    setError(false)
    return toggleComponent(id)
  }, [toggleComponent, setError])
  const handleCheck = useCallback((value: boolean) => {
    if (value) {
      window.dataLayer.push({"event":"costestimationstep3","addspare":"no"})
      clean();
    }
    setChecked(value);
    setError(false);
  }, [clean, setChecked, setError])
  const handleSubmit = useCallback((event?: FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (checked || (
        selected.length > 0 && 
        selected.every((s) => s.materials?.length > 0 
          ? s.materials.some((m) => m.selected)
          : true
        )
      )
    ) {
      routing.navigateTo('/estimate');
    } else {
      setInvalid(true);
      setError(true);
      setTimeout(() => setInvalid(false), 1000);
    }
  }, [checked, routing, setError, setInvalid, selected]);
  return (
    <div id="page-services">
      <Navbar step={2} />
      <Layout>
        <Sidebar step={2} />
        <Main className={classNames({ invalid, error })}>
          <h2>{t('ADDSRV_TITLE')}</h2>
          <h3>{t('ADDSRV_SUBTITLE')}</h3>
          <p>{t('ADDSRV_SPAREPARTS_TITLE_1')} </p>
          <p>{t('ADDSRV_SPAREPARTS_TITLE_2')} </p>
          <form onSubmit={handleSubmit}>
            <fieldset>
              <legend>{t('ADDSRV_SPAREPARTS_TITLE')}</legend>
              <Checkbox 
                checked={checked}
                onChange={handleCheck}
                label={t('ADDSRV_NO_ADDITIONAL_SRV')} 
              />
              <Grid disabled={checked}>
                {components.map((comp, index) =>
                  <GridItem
                    active={!!selected.find(c => c.id === comp.id)}
                    options={comp.materials.map((m) => ({ label: t(m.name), value: m.id, selected: m.selected }))}
                    key={index}
                    label={t(comp.name)}
                    value={comp.id}
                    onSelect={onSelect}
                  />
                )}
              </Grid>
            </fieldset>
            <Button next
              onClick={handleSubmit}
              legend={t('BUTTON_NEXT', { current: 3, total: 3 })}
              label={t('ESTIMATION_TITLE')}
            />
          </form>
        </Main>
      </Layout>
      <Footer />
    </div>
  );
};

export default memo(Spareparts);