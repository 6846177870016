import React, { useCallback } from 'react';
import './style.scss';
import { useTranslation } from 'react-multi-lang';
import Button from 'components/Button';
import { vacWebsite, conciergePath } from 'constants/links';
import { useSelector } from 'reducers';

export default () => {
  const t = useTranslation();
  const lang = useSelector((state) => state.lang);
  const contactConcierge = useCallback(() => {
    window.open(`${vacWebsite}/${lang}/${conciergePath}`);
  }, [lang]);
  return (
  <div id="ref-not-found">
    <div className="content">
      <h2>{t('HOME_CANTFIND_TITLE')}</h2>
      <p>{t('HOME_CANTFIND_DESCRIPTION')}</p>
      <Button
        next
        label={t('HOME_CANTFIND_TITLE')}
        onClick={contactConcierge}
      />
    </div>
  </div>
  );
}