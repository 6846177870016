import { IBaseState, IAction } from "recost";
import { IWatch } from "services/api/models/watch";

export const FETCH_WATCH_PENDING = 'FETCH_WATCH_PENDING';
export const FETCH_WATCH_FULFILLED = 'FETCH_WATCH_FULFILLED';
export const FETCH_WATCH_REJECTED = 'FETCH_WATCH_REJECTED';
export const DESELECT_WATCH = 'DESELECT_WATCH';

export interface IWatchState extends IBaseState {
  watch?: IWatch,
  loadingWatch?: boolean,
  watchNotFound?: boolean,
};

export const watchInitialState: IWatchState = {};

export default function watchReducer(state: IBaseState, action: IAction) {
  switch (action.type) {
    case DESELECT_WATCH:
      return {
        ...state,
        watch: undefined,
      };
    case FETCH_WATCH_PENDING:
      return {
        ...state,
        loadingWatch: true,
      };
    case FETCH_WATCH_FULFILLED:
      return {
        ...state,
        loadingWatch: false,
        watch: action.payload,
        watchNotFound: false,
      };
    case FETCH_WATCH_REJECTED:
      return {
        ...state,
        loadingWatch: false,
        watchNotFound: true,
      };
    default:
      return state;
  }
}