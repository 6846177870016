import React, { FC, memo, ReactNode } from 'react';
import classNames from 'classnames';
import './style.scss';

export interface IMainProps {
  className?: string,
  children: ReactNode,
}

const Main: FC<IMainProps> = ({ children, className = '' }) => {
  return (
    <div className={classNames({ 'main': true, [className]: !!className })}>
      {children}
    </div>
  );
};

export default memo(Main);