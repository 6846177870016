import { useEffect, useCallback, useState } from 'react';
import api from 'services/api';
import { dispatch, useSelector } from 'reducers';
import { FETCH_SERVICES_PENDING, FETCH_SERVICES_FULFILLED, FETCH_SERVICES_REJECTED } from 'reducers/service';
import HttpError from '../HttpError';
import { useRouting } from 'services/routing';

export interface IService {
  id: string,
  name?: { [lang: string]: string },
  selected?: boolean,
  duringCompleteService: boolean,
  isCompleteService?: boolean,
  sapCode: string,
};

export function mapServices(services: IService[], selected: string[] = []): IService[] {
  return services.map((serv) => ({
    ...serv,
    selected: !!selected.find((s) => s.includes(serv.sapCode)),
  }));
}

export function selectedServices(services: IService[], selected: string[] = []): IService[] {
  return services.filter((serv) => {
    return !!selected.find((s) => s.includes(serv.sapCode))
  })
}

export function useServices() {
  const { params, setParams } = useRouting();
  const productId = params.id
  const services = useSelector((state) => state.services);
  const observations = useSelector((state) => state.observations);
  const [prevObs, setPrevObs] = useState<string[]>(params?.observations || []);
  const loading = useSelector((state) => state.loadingServices);
  const error = useSelector((state) => state.servicesError);
  const [completeService, setCompleteService] = useState(false);
  useEffect(() => {
    if (JSON.stringify(prevObs) === JSON.stringify(params.observations)) {
      return
    }
    setPrevObs(params.observations || [])
    const completeService = (params.observations || []).some((id) => {
      return !!observations.find((obs) => {
        return (obs.id === id && obs.completeService) || 
          obs.sub?.find((sub) => sub.id === id && sub.completeService)
      });
    });
    setCompleteService(completeService);
    if (completeService) {
      setParams({ services: [] });
    }
  }, [observations, params.observations, setParams, setPrevObs, prevObs]);

  useEffect(() => {
    list(productId)
  }, [productId]);

  const toggleService = useCallback((id: string) => {
    const serv = services.find((serv) => serv.id === id);
    if (serv && serv.sapCode) {
      let services = params.services || [];
      if (services.includes(serv.sapCode)) {
        if (serv.isCompleteService) {
          services = []
        } else {
          services = services.filter((sapCode) => sapCode !== serv.sapCode);
        }
      } 
      else {
        if (serv.isCompleteService) {
          services = [serv.sapCode];
        } else {
          services = [...services, serv.sapCode];
        }
      }
      setParams({ services });
    }
  }, [services, setParams, params.services]);
  const selected = selectedServices(services, params.services);
  const mapped = mapServices(services, params.services).filter((srv) => {
    if (selected.find((srv) => srv.isCompleteService) || completeService) {
      return srv.duringCompleteService || srv.duringCompleteService === null;
    } else {
      return !srv.duringCompleteService;
    }
  });
  return { 
    services: mapped,
    selected,
    loading, 
    error,
    toggleService,
  };
}

export function list(productId?: string, observations: string[] = []) {
  dispatch({ type : FETCH_SERVICES_PENDING });
  return api.get(`/services?productId=${productId}`).then((services: IService[]) => {
    if (services) {
      dispatch({ 
        type: FETCH_SERVICES_FULFILLED, 
        payload: services,
      });
      return services;
    } else {
      throw new HttpError({ status: 500 });
    }
  }).catch((error) => {
    dispatch({ type: FETCH_SERVICES_REJECTED });
    throw error;
  })
}
