import { vacStoreWebsite, yextFormPath } from 'constants/links'

export function buildYextUrl(lang: string) {
  switch (lang) {
    case 'en':
      return `${vacStoreWebsite}/${yextFormPath}${window.location.href}`
    case 'zh_tw':
      return `${vacStoreWebsite}/zh_hant/${yextFormPath}${window.location.href}`
    case 'zh_cn':
      return `${vacStoreWebsite}/zh_hans/${yextFormPath}${window.location.href}`
    default:
      return `${vacStoreWebsite}/${lang}/${yextFormPath}${window.location.href}`
  }
}