import React, { FC, memo } from 'react';
import { useTranslation } from 'react-multi-lang';
import { useRegions } from 'services/api/models/regions';

export interface IPriceProps {
  value?: number | null,
};

const Price: FC<IPriceProps> = ({ value }) => {
  const t = useTranslation();
  const { region = { symbol: '' } } = useRegions()
  const formatedValue = (value === null || value === undefined)
    ? t('WORD_ON_ESTIMATE')
    : value === 0
      ? t('ESTIMATION_FREE_OF_CHARGE')
      : <>
        {region.symbol + " "}
        {value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </>
  return (
    <span className="price">
      {formatedValue}
    </span>
  );
}

export default memo(Price);