import React, { FC } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from 'services/routing';

import Header from 'components/Header';

import Home from 'views/Home';
import Diagnosis from 'views/Diagnosis';
import Services from 'views/Services';
import Estimate from 'views/Estimate';

const RouteView: FC = () => {
  return (
    <Router history={history}>
      <Header />
      <Switch>
        <Route path="/:lang/diagnosis" component={Diagnosis} />
        <Route path="/:lang/services" component={Services} />
        <Route path="/:lang/estimate" component={Estimate} />
        <Route path="/:lang" component={Home} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  )
}

export default RouteView;