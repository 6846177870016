import React, { memo, FC, useCallback } from 'react';
import { useRouting } from 'services/routing';
import { useWatch } from 'services/api/models/watch';
import Button from 'components/Button';
import Estimation from 'components/Estimation';
import './style.scss';
import { useTranslations } from 'services/multilang';

export interface ISidebarProps {
  step: number
};


const Sidebar: FC<ISidebarProps> = ({ step }) => {
  const [watch] = useWatch();
  const routing = useRouting();
  const t = useTranslations();
  const selectOther = useCallback(() => {
    const params = {
      id: '',
      observations: [],
      services: [],
      components: [],
    };
    routing.navigateTo('/', params);
  }, [routing]);
  return (
    <aside className={`sidebar step${step}`}>
      <div className="logo-small"></div>
      <div className="reference">
        {t('SIDEBAR_REFERENCE')}: {watch?.internalRef}
      </div>
      <div className="title">
        {(watch && watch.name)
          ? t(watch.name)
          : (watch && watch.collection)
            ? t(watch.collection)
            : t('SIDEBAR_WATCH_PLACEHOLDER')
        }
      </div>
      <Button
        className="back"
        label={t('SIDEBAR_SELECT_ANOTHER')}
        onClick={selectOther} 
      />
      <Estimation sidebar />
    </aside>
  );
};

export default memo(Sidebar);