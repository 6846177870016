import { IBaseState, IAction } from "recost";
import { ILang } from "services/api/models/translations";

export const FETCH_LANGS_PENDING = 'FETCH_LANGS_PENDING';
export const FETCH_LANGS_FULFILLED = 'FETCH_LANGS_FULFILLED';
export const FETCH_LANGS_REJECTED = 'FETCH_LANGS_REJECTED';
export const SET_LANG = 'SET_LANG';

export interface ITranslationState extends IBaseState {
  langs: ILang[],
  loadingLangs?: boolean,
  langsError?: boolean,
  lang?: string,
};

export const translationInitialState: ITranslationState = {
  langs: [],
  loadingLangs: false,
  langsError: false,
  lang: 'en',
};

export default function translationReducer(state: IBaseState, action: IAction) {
  switch (action.type) {
    case FETCH_LANGS_PENDING:
      return {
        ...state,
        loadingLangs: true,
      };
    case FETCH_LANGS_FULFILLED:
      return {
        ...state,
        langs: action.payload,
        loadingLangs: false,
        langsError: false,
      };
    case FETCH_LANGS_REJECTED:
      return {
        ...state,
        langError: true,
      };
    case SET_LANG:
      return {
        ...state,
        lang: action.payload,
      }
    default:
      return state;
  }
}