import React, { ChangeEvent } from 'react'

export function AgePrompt({ t, age, update } : { t: any, age: number, update: (age?: number) => void }){
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    update(Number(event?.target.value))
  }
  return <div className="age-prompt">
    {t('MY_LAST_BATTERY_SERVICE_WAS')}
    <input step={1} min={0} type="number" value={age < 999 ? age : ''} onChange={handleChange} />
    {t('YEARS_AGO')}
  </div>
}