import { IBaseState, IAction } from "recost";
import { IComponent } from "services/api/models/components";

export const FETCH_COMPONENTS_PENDING = 'FETCH_COMPONENTS_PENDING';
export const FETCH_COMPONENTS_FULFILLED = 'FETCH_COMPONENTS_FULFILLED';
export const FETCH_COMPONENTS_REJECTED = 'FETCH_COMPONENTS_REJECTED';

export interface IComponentState extends IBaseState {
  components: IComponent[],
  loadingComponents: boolean,
  componentsError: boolean,
};

export const componentInitialState: IComponentState = {
  components: [],
  loadingComponents: false,
  componentsError: false,
};

export default function componentReducer(state: IComponentState, action: IAction) {
  switch (action.type) {
    case FETCH_COMPONENTS_PENDING:
      return {
        ...state,
        loadingComponents: true,
      };
    case FETCH_COMPONENTS_FULFILLED:
      return {
        ...state,
        components: action.payload,
        loadingComponents: false,
      };
    case FETCH_COMPONENTS_REJECTED:
      return {
        ...state,
        componentsError: true,
      };
    default:
      return state;
  };
};