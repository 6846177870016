import React, { memo, FC } from 'react';
import { useServices } from 'services/api/models/services'
import { useObservations } from 'services/api/models/observations'
import { useTranslations } from 'services/multilang';
import classNames from 'classnames';
import logo from 'assets/logo-header.svg';
import "./style.scss";
import Price from './Price';
import { IEstimation, IServiceEstimation } from 'services/api/models/estimations';
import { useComponents } from 'services/api/models/components';
import { useRegions } from 'services/api/models/regions';

export interface IEstimationProps extends Partial<IEstimation> {
  sidebar?: boolean,
};

const Estimation: FC<IEstimationProps> = (props) => {
  const t = useTranslations();
  const { selected: observations = [] } = useObservations();
  const { selected: selectedServices = [] } = useServices();
  const { selected: selectedComponents = [] } = useComponents();
  const services = (props.services || selectedServices) as IServiceEstimation[];
  const components = (props.components || selectedComponents) as IServiceEstimation[];
  const estimationClasses = classNames({ 
    'estimation-box': true,
    'estimation-sidebar': props.sidebar,
  });
  const { region } = useRegions()
  return (
    <div className={estimationClasses}>
      <img className="logo" src={logo} alt={t('APP_NAME')} />
      {observations.length > 0 && (
        <section className="estimation-diagnosis">
          <h1>{t('DIAGNOSIS_TITLE')}</h1>
          <ul>
            {observations.map((obs, index) =>
              <li key={index}>{t(obs.name)} <Price value={810} /></li>
            )}
          </ul>
        </section>
      )}
      {services.length > 0 && (
        <section className="estimation-service">
          <h1>{t('WORD_SERVICE')} <Price value={props.totalServices} /></h1>
          <ul>
            {services.map((srv, index) =>
              <li key={index}>{t(srv.name)} <Price value={srv.price} /></li>
            )}
          </ul>
        </section>
      )}
      {components.length > 0 && (
        <section className="estimation-spareparts">
          <h1>{t('ADDSRV_TITLE')} <Price value={props.totalComponents} /></h1>
          <ul>
            {components.map((comp, index) =>
              <li key={index}>{
                comp.laborCost
                  ? `${t('WORD_LABOR_COST')} (${t(comp.name)})` 
                  : t(comp.name)
              } <Price value={comp.price} /></li>
            )}
          </ul>
        </section>
      )}
      <section className="total">
        <h1>
          {t('WORD_TOTAL')}
          {region?.vat === 0 ? <span className="vat-excluded">{t('VAT_EXCLUDED')}</span> : null}
          <Price value={props.total} />
        </h1>
      </section>
    </div>
  );
};

export default memo(Estimation);