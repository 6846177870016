import { IBaseState, IAction } from "recost";
import { IRegion } from "services/api/models/regions";

export const FETCH_REGIONS_PENDING = 'FETCH_REGIONS_PENDING';
export const FETCH_REGIONS_FULFILLED = 'FETCH_REGIONS_FULFILLED';
export const FETCH_REGIONS_REJECTED = 'FETCH_REGIONS_REJECTED';

export interface IRegionState extends IBaseState {
  regions: IRegion[],
  loadingRegions?: boolean,
  regionsError?: boolean,
};

export const regionInitialState: IRegionState = {
  regions: [],
  loadingRegions: false,
  regionsError: false,
};

export default function regionReducer(state: IBaseState, action: IAction) {
  switch (action.type) {
    case FETCH_REGIONS_PENDING:
      return {
        ...state,
        loadingRegions: true,
      };
    case FETCH_REGIONS_FULFILLED:
      return {
        ...state,
        regions: action.payload,
        loadingRegions: false,
        regionsError: false,
      };
    case FETCH_REGIONS_REJECTED:
      return {
        ...state,
        regionsError: true,
      };
    default:
      return state;
  }
}