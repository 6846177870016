import React, { FC, memo, useState, useCallback, FormEvent, useEffect } from 'react';
import { sanitizeInternalRef, searchWatch } from 'services/api/models/watch';
import { useRegions } from 'services/api/models/regions';
import { useDebounce } from 'utils/hooks';
import Input from 'components/Input';
import Button from 'components/Button';
import { useTranslation } from 'react-multi-lang';

export interface IFormData {
  internalRef?: string,
  region?: string,
};

export interface IFormProps extends IFormData {
  openModal: () => void,
  contactConcierge: () => void,
  submit?: (region?: string, watch?: string) => void,
};

const Form: FC<IFormProps> = ({ internalRef, submit, openModal, contactConcierge }) => {

  const {regions, region, setRegion} = useRegions();
  const [options, setOptions] = useState<string[]>([]);
  const t = useTranslation();
  
  const [value, setValue] = useState<string>('');
  const [invalid, setInvalid] = useState(false);

  const handleChange = useCallback((value, name) => {
    if (name === 'region') {
      setRegion(value);
    } else {
      setValue(value);
      if (!value) {
        setOptions([])
      }
    }
  }, [setValue, setRegion]);

  useEffect(() => {
    setValue(internalRef || '');
  },[internalRef, setValue]);

  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    if (!debouncedValue) {
      return setOptions([]);
    }
    searchWatch(debouncedValue).then((watches) => {
      const options = [
        ...watches.map((watch) => watch.internalRef),
      ].filter((v, i, array) => {
        return array.indexOf(v) === i &&
          sanitizeInternalRef(v).includes(sanitizeInternalRef(debouncedValue))
      })
      setOptions(options);
    });
  }, [debouncedValue, setOptions]);

  const handleSubmit = useCallback(async (event?: FormEvent) => {
    setInvalid(false);
    if (event) {
      event.preventDefault();
    }
    if (value) {
      submit?.(value);
    } else {
      setTimeout(() => setInvalid(true), 1);
    }
  }, [value, submit, setInvalid]);  

  return (
    <form onSubmit={handleSubmit}>
      <Input
        placeholder="1234/000X-0000"
        error={invalid}
        autocomplete
        options={options}
        onChange={handleChange}
        onSubmit={handleSubmit}
        name="internalRef"
        label={<span className="ref-label">
          {t('HOME_INPUT_REF1')}
          <button aria-label={t('HOME_HELP_ICON')} type="button" className="help" onClick={openModal}></button>
        </span>}
        value={value}
      />
      <button aria-label={t('HOME_NO_REF')} onClick={contactConcierge} type="button" className="no-ref">
        {t('HOME_NO_REF')}
      </button>
      <Input 
        onChange={handleChange}
        name="region"
        type="select" 
        options={regions?.map((r) => ({ label: t(r.id), value: r.id })).sort((a, b) => a.label.localeCompare(b.label))}
        label={t('HOME_INPUT_REGION')}
        value={region?.id}
      />
      <Button next
        type="submit"
        onClick={handleSubmit}
        label={t('HOME_BUTTON_SUBMIT')}
      />
    </form>
  )
}

export default memo(Form);