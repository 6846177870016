import React, { memo, FC } from 'react';
import classNames from 'classnames';
import './style.scss';

export interface IButtonProps {
  next?: boolean,
  cta?: boolean,
  legend?: string,
  icon?: string,
  type?: 'submit' | 'button',
  label?: string,
  onClick: () => void,
  className?: string,
};

const Button: FC<IButtonProps> = ({ cta = false, next = false, type = 'button', label, children, onClick, className, legend }) => {

  const buttonClasses = classNames({
    'button': true,
    'next': next,
    'cta': cta,
    [className || '']: !!className,
  });
  
  return (
    <button aria-label={label} type={type} onClick={onClick} className={buttonClasses}>
      {legend && (
        <div className="button-legend">{legend}</div>
      )}
      {label}
      {children}
    </button>
  );
};

export default memo(Button);