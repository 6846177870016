import React, { FC, memo, useCallback, useState, useEffect } from 'react';
import queryString from 'query-string'
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'reducers';
import { useEstimation } from 'services/api/models/estimations';
import { useRouting } from 'services/routing';
import Navbar from 'components/Navbar';
import Layout from 'components/Layout';
import Sidebar from 'components/Sidebar';
import Main from 'components/Main';
import Footer from 'components/Footer';
import Button from 'components/Button';
import Estimation from 'components/Estimation';
import "./style.scss";
import { buildYextUrl } from 'utils/yext';
import { generatePDF } from 'services/pdf';

const EstimateView: FC = () => {
  const [windowUrl, setWindowUrl] = useState('');
  const lang = useSelector((state) => state.lang) || 'en';
  const t = useTranslation();
  const { params } = useRouting();
  const { estimation = {}, kitUrl } = useEstimation(params);
  useEffect(() => {
    if(params.print) {
      document.body.classList.add('print')
    }
  }, [params.print])
  useEffect(() => {
    const search = queryString.parse(window.location.search)
    delete search.print
    const q = Object.keys(search).map((key) => `${key}=${search[key]}`).join('&')
    const url = window.location.origin + window.location.pathname + '?' + q 
    setWindowUrl(url);
    window.dataLayer.push({"event": "estimatepreview"})
    document.title = `VACHERON CONSTATIN_${t('APP_NAME')}_${t('ESTIMATION_TITLE')}`
  }, [estimation, t, params]);
  const appointment = useCallback(() => {
    window.dataLayer.push({"event": "appointment"})
    window.open(buildYextUrl(lang));
  }, [lang]);
  const shippingKit = useCallback(() => {
    window.dataLayer.push({"event": "shipping-kit"})
    if (kitUrl) window.open(kitUrl);
  }, [kitUrl])
  const printPDF = useCallback(() => {
    window.dataLayer.push({"event": "pdfdownload", "pdf": windowUrl})
    generatePDF()
    //window.print()
  }, [windowUrl])
  return (
    <div id="page-estimate">
      <Navbar step={3} />
      <Layout>
        <Sidebar step={3} />
        <Main>
          <h2>{t('ESTIMATION_TITLE')}</h2>
          <div className="estimation-container">
            <h4>{t('ESTIMATION_SUBTITLE')}</h4>
            <Button cta
              label={t('BUTTON_DOWNLOAD_PDF')} 
              onClick={printPDF}
            />
            <div className="disclaimer">
              {t('ESTIMATION_DISCLAIMER')}
            </div>
            {kitUrl ? <Button next 
              label={t('ESTIMATION_TARGET_BUTTON')}
              onClick={shippingKit}
            /> : null}
            <Button next 
              label={t('BUTTON_APPOINTMENT')}
              onClick={appointment}
            />
            <Estimation {...estimation} />
            <a href={windowUrl} className="print-footer">
              {windowUrl}
            </a>
          </div>
        </Main>
      </Layout>
      <Footer />
    </div>
  );
}

export default memo(EstimateView);