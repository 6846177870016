import React, { memo, FC } from 'react';
import { useTranslation } from 'react-multi-lang';
import classNames from 'classnames';
import Button from 'components/Button';
import { scrollTop, isInsideIframe } from 'utils';
import './style.scss';

const Footer: FC = () => {
  const t = useTranslation();
  const footerClasses = classNames({
    'footer': true,
    'inside-iframe': isInsideIframe(),
  });
  return (
    <footer className={footerClasses}>
      <Button
        className="scrollTop"
        label={t('BUTTON_SCROLL_TOP')}
        onClick={scrollTop}
      />
    </footer>
  )
}

export default memo(Footer);