import React, { FC, memo, useCallback, useState, FormEvent } from 'react';
import Navbar from 'components/Navbar';
import Layout from 'components/Layout';
import Sidebar from 'components/Sidebar';
import Main from 'components/Main';
import { List, ListItem } from 'components/List';
import "./style.scss";
import { useObservations } from 'services/api/models/observations';
import { useServices } from 'services/api/models/services';
import Button from 'components/Button';
import { useRouting } from 'services/routing';
import Footer from 'components/Footer';
import { useTranslations } from 'services/multilang';
import classNames from 'classnames';
import { AgePrompt } from './age';

function renderItems(t: any, list: any[], toggle: (id: string) => void, age: number, updateAge: (age?: number) => void) {
  if (list.every((item) => item.ageMin || item.ageMax)) {
    return <AgePrompt t={t} age={age} update={updateAge} />
  }
  return list.sort((a, b) => (a.order || 0) - (b.order || 0)).map((item) => (
    <ListItem
      active={item.selected}
      onClick={toggle}
      key={item.id}
      label={t(item.name) || item.id} 
      value={item.id}
      mouseover={t(item.mouseover)}
    >
      {item.sub && (
        renderItems(t, item.sub, toggle, age, updateAge)
      )}
    </ListItem>
  ));
}

const Diagnosis: FC = () => {
  const { observations, toggleObservation, selected: selectedObs, age, updateAge } = useObservations();
  const { services, toggleService, selected: selectedSrv } = useServices();
  const { navigateTo } = useRouting();
  const t = useTranslations();
  const [invalid, setInvalid] = useState(false);
  const handleSubmit = useCallback((event?: FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (selectedSrv.length > 0 || selectedObs.length > 0) {
      navigateTo('/services');
    } else {
      setInvalid(true);
      setTimeout(() => setInvalid(false), 1000);
    }
  }, [navigateTo, selectedObs, selectedSrv, setInvalid]);
  return (
    <div id="page-diagnosis">
      <Navbar step={1} />
      <Layout>
        <Sidebar step={1} />
        <Main className={classNames({ invalid })}>
          <h2>{t('DIAGNOSIS_TITLE')}</h2>
          <h3>{t('DIAGNOSIS_SUBTITLE')}</h3>
          <form onSubmit={handleSubmit}>
            <List title={t('DIAGNOSIS_OBSERVATIONS_TITLE')}>
              {renderItems(t, observations, toggleObservation, age || 0, updateAge)}
            </List>
            <List title={t('DIAGNOSIS_SERVICES_TITLE')}>
              {renderItems(t, services, toggleService, age || 0, updateAge)}
            </List>
            <Button next
              onClick={handleSubmit}
              legend={t('BUTTON_NEXT', { current: 2, total: 3 })}
              label={t('ADDSRV_TITLE')}
            />
          </form>
        </Main>
      </Layout>
      <Footer />
    </div>
  );
};

export default memo(Diagnosis);