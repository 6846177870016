import { API_ADDRESS } from "constants/api"

/*function getBinary(file: string) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", `${window.location.origin}${file}`, false);
  xhr.overrideMimeType("text/plain; charset=x-user-defined");
  xhr.send(null);
  return xhr.responseText;
}

function base64Encode(str: string) {
  var CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  var out = "", i = 0, len = str.length, c1, c2, c3;
  while (i < len) {
    c1 = str.charCodeAt(i++) & 0xff;
    if (i == len) {
      out += CHARS.charAt(c1 >> 2);
      out += CHARS.charAt((c1 & 0x3) << 4);
      out += "==";
      break;
    }
    c2 = str.charCodeAt(i++);
    if (i == len) {
      out += CHARS.charAt(c1 >> 2);
      out += CHARS.charAt(((c1 & 0x3)<< 4) | ((c2 & 0xF0) >> 4));
      out += CHARS.charAt((c2 & 0xF) << 2);
      out += "=";
      break;
    }
    c3 = str.charCodeAt(i++);
    out += CHARS.charAt(c1 >> 2);
    out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
    out += CHARS.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >> 6));
    out += CHARS.charAt(c3 & 0x3F);
  }
  return out;
}*/

export function generatePDF() {
  let rootDiv = document.getElementById('root')?.innerHTML || ''
  let style = ''
  for (const styleTag of document.getElementsByTagName('style')) {
    style += `<style>${styleTag.innerText}</style>`
  }

  /*const fontPaths = style.match(/\/static\/[^"]+\)/g)?.map(paths => paths.split(')')[0])
  fontPaths?.forEach((path) => {
    const base64Encoded = base64Encode(getBinary(path));
    const fontUrl = `'data:application/font-woff;base64,${base64Encoded}'` 
    style = style.replace(path, fontUrl)
  })

  const srcPaths = rootDiv.match(/"\/static\/[^"]+"/g)?.map(path => path.split('"')[1].split('"')[0])
  srcPaths?.forEach((path) => {
    const base64Encoded = base64Encode(getBinary(path));
    const assetUrl = `data:image/svg+xml;base64,${base64Encoded}`
    rootDiv = rootDiv.replace(path, assetUrl)
  })*/

  const html = `
    <html>
    <head>
      ${style}
    </head>
    <body class="print">
      ${rootDiv}
    </body>
    </html>
  `
  //console.log(html)

  const form = document.createElement('form')
  form.setAttribute('action', `${API_ADDRESS}/pdf`)
  form.setAttribute('method', 'post')
  const input = document.createElement('input')
  input.setAttribute('type', 'hidden')
  input.setAttribute('name', 'html')
  input.setAttribute('value', encodeURIComponent(html))
  form.appendChild(input)
  document.body.appendChild(form)
  form.submit()
}