import React, { FC, memo, useCallback } from 'react';
import { useRouting } from 'services/routing';
import Button from 'components/Button';
import './style.scss';
import { useTranslation } from 'react-multi-lang';

export interface IHeaderProps {
  step?: number,
};

const Header: FC<IHeaderProps> = ({ step = 0 }) => {
  const t = useTranslation();
  const routing = useRouting();
  let path: string;
  let label: string;
  switch (step) {
    case 3:
      path = '/services';
      label = t('NAV_BACK_TO_SERVICES');
      break;
    case 2:
      path = '/diagnosis';
      label = t('NAV_BACK_TO_DIAGNOSIS');
      break;
    case 1:
    case 0:
    default:
      path = '/';
      label = t('NAV_BACK_TO_HOME');
      break;
  }
  const prev = useCallback(() => {
    routing.navigateTo(path);
  }, [routing, path]);
  return (
    <nav className="navbar">
      <h1>{t('APP_NAME')}</h1>
      <Button 
        onClick={prev}
        label={label}
      />
    </nav>
  );
};

export default memo(Header);