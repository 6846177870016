import React, { memo, FC, MouseEvent, useRef, useCallback, ComponentType } from 'react';
import classNames from 'classnames';
import "./style.scss";

export interface IModalProps {
  onClose: () => void,
  visible?: boolean,
  content?: ComponentType<{}>,
}

const Modal: FC<IModalProps> = ({ onClose, visible = false, children, content }) => {
  const node = useRef<HTMLDivElement>(null);
  const Content = content
  
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (!node.current?.contains(event.target as Node)) {
      onClose();
    }
  }, [onClose, node]);

  const modalClasses = classNames({
    'modal-background': true,
    'modal-visible': visible,
  });

  return (
    <div className={modalClasses} onClick={handleClickOutside}>
      <div ref={node} className="modal-box">
        <button aria-label="close" className="modal-close" onClick={onClose}></button>
        <div className="modal-content">
          {Content && <Content />}
          {children}
        </div>
      </div>
    </div>
  );
};

export default memo(Modal);